<template>
  <v-layout
    column
    justify-center
    fill-height
    class="giphy-mission"
    :class="{ mobile: mobile }"
  >
    <template v-if="selectedImage && missionCompleted">
      <v-flex d-flex shrink class="giphy-mission__misson-complete-message">
        Waiting for other scribes to complete...
      </v-flex>
      <v-flex class="giphy-mission__selected-image">
        <img :src="selectedImage" alt />
      </v-flex>
    </template>

    <template v-else>
      <TextField
        v-if="textFieldVisible"
        :placeholder="placeholder"
        :status="status"
        :show="'show'"
        button-text="SEARCH"
        size="sm"
        :class="buttonStatus"
        @onSubmit="getGif"
      />

      <v-flex v-if="instructionsVisible" d-flex class="mission-instructions">
        <ResizableText :message="instructions" />
      </v-flex>

      <v-flex
        d-flex
        align-center
        justify-center
        class="error-message"
        v-if="error"
      >
        {{ error }}
      </v-flex>
      <v-flex
        v-show="!error"
        class="carousel-wrap"
        :class="{ show: gifs.length && mode !== 'explain' && !error }"
      >
        <CarouselControls
          v-if="isScribe"
          :prev-disabled="currentPage === 0"
          :next-disabled="loading"
          @prev="prevSlide"
          @next="nextSlide"
        />
        <Carousel
          :perPage="nOfImagesPerSearch"
          :paginationEnabled="false"
          :navigationEnabled="false"
          :mouseDrag="false"
          v-if="gifs.length"
          class="carousel pt-1"
          ref="carousel"
          :key="`giphy-${searchQuery}-${syncedQuery}`"
          @pageChange="pageChange"
        >
          <Slide v-for="(gif, i) in gifs" :key="`giphy-${i}`">
            <v-flex
              fill-height
              style="position: relative"
              class="carousel-image-wrap"
            >
              <v-progress-circular
                v-if="gif.loaded === false || gif.loaded === 'warning'"
                indeterminate
                class="spinner"
              />
              <div v-if="gif.loaded === 'warning'" class="loading-warn">
                Loading takes longer than usual. Try another one
              </div>
              <img
                @click="foo = false"
                :src="gif.url"
                class="carousel-image"
                @load="onLoad(i)"
                v-show="!!gif.loaded && gif.loaded !== 'warning'"
              />
            </v-flex>
          </Slide>
        </Carousel>
      </v-flex>
      <v-flex
        v-if="selectedGif && isScribe"
        class="pt-1"
        :class="{ 'mobile-submit-btn': mobile }"
      >
        <v-tooltip bottom class="flex-shrink-0">
          <template v-slot:activator="{ on }">
            <rtb-button-next
              size="sm"
              color="success"
              @click="submit"
              v-on="on"
              style="margin-bottom: 6px"
            >
              SUBMIT
            </rtb-button-next>
          </template>
          <span>Submit your Giphy</span>
        </v-tooltip>
      </v-flex>

      <MissionStatus
        class="mb-3"
        v-if="!isPresenterUser && !isHost"
        :show="statusText"
      >
        {{ statusText }}
      </MissionStatus>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { Carousel, Slide } from "vue-carousel"
import * as moment from "moment"
import Mode from "@shared/enums/Mode"
import { RtbButtonNext } from "@/components/ui"
import { GameMixin } from "@/mixins"
import CarouselControls from "@/components/CarouselControls/CarouselControls.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"

import TextField from "./TextField"
import User from "@shared/User"

const TIMEOUT_DELAY = 10000

export default {
  name: "Giphy",
  mixins: [GameMixin],
  components: {
    MissionStatus,
    Ellipsis,
    CarouselControls,
    ResizableText,
    TextField,
    Carousel,
    Slide,
    RtbButtonNext
  },
  props: {
    mode: String,
    mission: Object,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.$store.dispatch("subscribeToGiphy")
  },
  mounted() {
    this.cleanSyncedGifs()
    if (this.canSync) {
      this.userIDToWatch = this.getRandomTeammateID()
    } else {
      // clean any gif records from the DB
      this.cleanSyncedGifs()
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      waitingText: "Waiting on Scribe...",
      searchQuery: "",
      gifs: [],
      nOfImagesPerSearch: 1,
      currentPage: 0,
      foo: false,
      captionText: "",
      selectedImage: null,
      userIDToWatch: null
    }
  },
  watch: {
    currentMissionID() {
      if (this.canSync) {
        this.userIDToWatch = this.getRandomTeammateID()
      } else {
        // clean any gif records from the DB
        this.cleanSyncedGifs()
      }
    },
    globalTeamID() {
      if (!(this.isHost || this.isAudit)) return
      this.gifs = []
      this.userIDToWatch = this.getRandomTeammateID()
      const index = parseInt(this.syncedIndex)
      if (isNaN(index)) return
      this.$nextTick(() => {
        console.log("ANIMATE FOR HOST")
        if (this.$refs.carousel) this.$refs.carousel.goToPage(index)
      })
    },
    currentPage(value) {
      const index = parseInt(value)
      if (!this.canSync) {
        if (!isNaN(index)) {
          this.onIndexChange(index)
        }
      }
    },
    canSync(value) {
      if (value) {
        this.gifs = []
        this.userIDToWatch = this.getRandomTeammateID()
      } else {
        this.gifs = []
        this.userIDToWatch = null
      }
    },
    syncedGifs: {
      handler: function (value) {
        if (!value) return
        if (!Array.isArray(value)) return
        if (!value.length) {
          this.gifs = []
          return
        }
        // figure which are already in cache/loaded
        const loaded = this.gifs.filter(obj => {
          return obj.loaded && value.includes(obj.url)
        })
        // apply the update array of urls but with respect
        // to the loaded state
        this.gifs = value.map(url => {
          if (loaded.some(obj => obj.url === url)) {
            return {
              url,
              loaded: true
            }
          } else {
            return {
              url,
              loaded: false
            }
          }
        })
      },
      deep: true
    },
    syncedIndex(value) {
      const index = parseInt(value)
      if (isNaN(index)) return
      this.$nextTick(() => {
        if (this.$refs.carousel) this.$refs.carousel.goToPage(index)
      })
    }
  },
  computed: {
    ...mapGetters("group", ["globalTeamID", "isViewerHostLike"]),
    ...mapGetters([
      "missionFail",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionAnswers",
      "isScribe",
      "teamID",
      "team",
      "chats"
    ]),
    ...mapGetters("auth", ["isAudit"]),
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    instructions() {
      if (this.missionCompleted) return "Waiting for others to complete..."
      return this.mission.instructions?.replace(
        "Enter a CAPTION to submit.",
        ""
      )
    },
    syncedGifs() {
      if (!this.syncedGifObject) return []
      if (!Array.isArray(this.syncedGifObject.gifs)) return []
      return this.syncedGifObject.gifs
    },
    syncedQuery() {
      if (!this.syncedGifObject) return null
      return this.syncedGifObject.query
    },
    syncedIndex() {
      if (!this.syncedGifObject) return 0
      const index = parseInt(this.syncedGifObject.index)
      return isNaN(index) ? 0 : index
    },
    syncedGifObject() {
      if (!this.userIDToWatch) return null
      const found = this.otherTeamPlayersGiphies.find(({ userID }) => {
        return userID === this.userIDToWatch
      })
      if (!found) return null
      return {
        gifs: found.gifs,
        index: found.index,
        query: found.query
      }
    },
    canSync() {
      return (
        this.otherTeamPlayersGiphies.length > 0 &&
        !this.isScribe &&
        this.teamMission
      )
    },
    giphies() {
      return this.$store.getters.giphies
    },
    giphiesArray() {
      if (!this.giphies) return []
      const giphies = []
      for (const giphyID in this.giphies)
        giphies.push({ ...this.giphies[giphyID], id: giphyID })

      return giphies
    },
    otherTeamPlayersGiphies() {
      return this.teamGiphies.filter(userID => userID !== this.user.id)
    },
    isHost() {
      return this.user.role === "facilitator"
    },
    teamGiphies() {
      return this.giphiesArray.filter(({ teamID }) => {
        if (this.isHost || this.isAudit) {
          return teamID === this.globalTeamID
        } else {
          return teamID === this.user.teamID
        }
      })
    },
    selectedGif() {
      if (this.foo) return null
      if (!this.gifs.length) return null
      else return this.gifs[this.currentPage]
    },
    status() {
      if (this.missionCompleted) return "inactive"
      if (this.isScribe) return "active"
      else return null
    },
    buttonStatus() {
      const isActive = !(this.captionText == "" || this.missionCompleted)
      return {
        inactive: !isActive,
        active: isActive
      }
    },
    placeholder() {
      // No data entered for a non scribe

      if (this.selectedGif) {
        return "Search again..."
      }

      // If scribe
      if (this.isScribe && this.mode !== Mode.Explain)
        return "Search Giphy here..."
      // Scribe but not ready to go
      return "Wait..."
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    pageCount() {
      if (!this.nOfImagesPerSearch) return 1
      return Math.ceil(this.gifs.length / this.nOfImagesPerSearch)
    },
    currentMissionID() {
      return this.mission?.id
    },
    textFieldVisible() {
      return (
        ![Mode.Welcome, Mode.Explain].includes(this.mode) &&
        (this.isScribe || this.isHost || this.isAudit)
      )
    },
    instructionsVisible() {
      return !this.gifs.length || this.mode === Mode.Explain
    },
    carouselVisible() {
      return this.gifs.length && this.mode !== Mode.Explain && !this.error
    },
    statusText() {
      if ([Mode.Welcome, Mode.Explain].includes(this.mode)) {
        return "Waiting for your turn..."
      }

      if (this.isWaiting) {
        return "Waiting on Scribe..."
      }

      return false
    },
    isWaiting() {
      return !this.isScribe && !this.missionCompleted
    }
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    getRandomTeammateID() {
      const random =
        this.otherTeamPlayersGiphies[
          this.getRandomInt(0, this.otherTeamPlayersGiphies.length - 1)
        ]
      if (!random) throw new Error("No users on the team")
      return random.userID
    },
    cleanSyncedGifs() {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })
      // console.log(found)
      if (found) {
        const timestamp = new Date()
        const obj = { ...found }
        obj.gifs = []
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
        this.$store.dispatch("addGiphy", obj)
      }
    },
    onIndexChange(index) {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })

      if (found) {
        const timestamp = new Date()
        const obj = { ...found }
        obj.index = index
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
        this.$store.dispatch("addGiphy", obj)
      }
    },
    onGiphy(url, query) {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })

      let obj
      const timestamp = new Date()

      if (found) {
        obj = { ...found }
        obj.gifs = []
        if (Array.isArray(found.gifs)) {
          if (found.query === query) {
            obj.gifs = found.gifs.map(gif => gif)
          }
        }
        obj.query = query
        obj.gifs.push(url)
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
      } else {
        obj = {}
        obj.query = query
        obj.userID = this.user.id
        obj.teamID = this.user.teamID
        obj.missionID = this.currentMissionID
        obj.mission = this.mission.name
        obj.created = moment(timestamp).format("LLLL")
        obj.updated = null
        obj.gifs = [url]
      }

      this.$store.dispatch("addGiphy", obj)
    },
    onLoad(index) {
      if (this.gifs[index]) {
        clearTimeout(this.gifs[index].timeout)
        this.gifs[index].loaded = true
      }
    },
    onCaptionChange(str) {
      this.captionText = str
    },
    nextSlide() {
      if (this.currentPage === this.pageCount - 1) {
        this.getGif(null, () => {
          this.$nextTick(() => {
            this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
          })
        })
      } else {
        this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
      }
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
    getGifPromise(url) {
      return new Promise((resolve, reject) => {
        // timeout for getting a response from Giphy
        const timeout = setTimeout(() => {
          console.error("Giphy timeout error")
          reject(new Error("We can't find a good match. Try again"))
        }, TIMEOUT_DELAY)
        fetch(url)
          .then(response => response.json())
          .then(res => {
            // if Giphy internal error
            if (res.message) {
              return reject(new Error(res.message))
              // if not array
            } else if (!Array.isArray(res.data)) {
              console.error("Got a non array response from Giphy")
              return reject(new Error("We can't find a good match. Try again"))
              // if doesn't have any values
            } else if (!res.data.length) {
              return reject(
                new Error(
                  `Sorry, no matches found for "${this.searchQuery}". Try a different search query`
                )
              )
            }
            // clear timeout error timer
            clearTimeout(timeout)
            return resolve(res.data)
          })
      })
    },
    getGif(str, callback = () => {}) {
      const API_KEY = "4vO355CnfW0k9iIvWnQ4W0UKkcLX4X9R"
      const SEARCH_END_POINT = "https://api.giphy.com/v1/gifs/search?"
      const currentSearchQuery = this.searchQuery
      // update only with a non null value
      if (str) this.searchQuery = str
      this.loading = true

      if (!this.searchQuery) {
        this.error = "Please enter a search query"
        this.foo = false
        this.gifs = []
        this.currentPage = 0
        this.loading = false
        return callback()
      }

      const url = `${SEARCH_END_POINT}&api_key=${API_KEY}&q=${this.searchQuery}&limit=${this.nOfImagesPerSearch}&offset=${this.gifs.length}&rating=g`

      this.getGifPromise(url)
        .then(array => {
          console.log("got results from Giphy", array)
          // reset current error
          this.error = null
          // push data
          array.forEach(gif => {
            const url = `https://media.giphy.com/media/${gif.id}/giphy.gif`
            // if a new query clean the array
            if (str) {
              if (str !== currentSearchQuery) {
                this.foo = false
                this.gifs = []
                this.currentPage = 0
              }
            }
            this.loading = false

            const obj = {
              url,
              loaded: false
            }

            // timeout for loading the image
            const timeout = setTimeout(() => {
              obj.loaded = "warning"
            }, TIMEOUT_DELAY)

            obj.timeout = timeout

            this.gifs.push(obj)
            this.onGiphy(url, this.searchQuery)
            callback()
          })
        })
        .catch(error => {
          console.log(error.message)
          this.error = error.message
          this.foo = false
          this.loading = false
          this.gifs = []
          this.currentPage = 0
        })
    },
    pageChange(n) {
      this.currentPage = n
    },
    async submit(string) {
      if (!this.isScribe) return console.log("No rights for submit")
      this.caption = " "
      if (!this.selectedGif || !this.caption) return
      if (!this.selectedGif.url) return
      this.answer = this.selectedGif.url
      this.selectedImage = this.selectedGif.url
      await this.checkAnswer()
    }
  }
}
</script>

<style lang="scss">
.giphy-mission .rtb-text-field {
  padding-top: 6px !important;
}
.giphy-mission {
  line-height: 1;
  font-size: 1em;

  &__misson-complete-message {
    padding: 8px;
    justify-content: center;
    color: #b6d6ff;
    line-height: 2;
    font-size: 12px;
    flex-basis: 24px;
  }

  &__selected-image {
    position: relative;
    justify-content: center;
    align-content: center;
    display: flex;

    img {
      object-fit: contain;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .loading-warn {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 25%;
    margin-top: 25px;
    font-size: 12px;
  }
  .carousel-image-wrap {
    position: relative;
  }
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $primary_accent_color;
  }
  .error-message {
    color: $wrong_color;
  }
  .rtb-text-field {
    width: 100%;
    padding: {
      top: 24px;
      left: 48px;
      right: 48px;
    }
  }
  .caption-text-field {
    min-width: 140px;
    max-width: 400px;
    & :nth-child(2n) {
      max-width: 0;
      &:hover {
        filter: brightness(1.2);
      }
      & > div {
        width: 80px;
        height: 100%;
        //background-color: $primary_accent_color;
        margin-left: 5px;
        color: $color-white;
        border-radius: 8px;
        a {
          color: $color-white;
        }
      }
    }
  }
  .v-text-field__slot {
    input {
      font-size: 15px !important;
    }
  }
  .caption-text-field.disabled {
    & :nth-child(2n) {
      &:hover {
        filter: none !important;
      }
      & > div {
        background-color: $color-disabled;
        filter: none !important;
        a {
          cursor: initial;
          filter: none !important;
        }
      }
    }
  }
  .VueCarousel-wrapper,
  .VueCarousel-inner,
  .VueCarousel {
    height: 100% !important;
  }
  .carousel-wrap {
    position: relative;
    opacity: 0;
    transition: all 0.3s ease-out;
    width: 100%;
    height: 100%;
    max-height: 0;
  }
  .carousel-wrap.show {
    opacity: 1;
    max-height: 100%;
  }
  .carousel-image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8px;
    cursor: pointer;
  }

  &.mobile {
    .rtb-text-field {
      padding-left: 0;
      padding-right: 0;

      .orientation-landscape & {
        padding-top: 32px;
      }
    }
  }
}
</style>
