
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
import { executeListener } from "../ui/helpers"

export default Vue.extend({
  name: "CarouselControls",
  functional: true,
  props: {
    prevDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, data, listeners }) {
    return h("div", mergeData(data, { staticClass: "carousel-controls" }), [
      h(
        "a",
        {
          attrs: {
            href: "#",
            role: "button"
          },
          staticClass:
            "carousel-controls__control carousel-controls__control--prev",
          class: {
            "carousel-controls__control--disabled": props.prevDisabled
          },
          on: props.prevDisabled
            ? undefined
            : {
                click: e => {
                  e.preventDefault()
                  executeListener(listeners.prev)
                }
              }
        },
        [h("svg-icon", { props: { name: "chevron-left-regular" } })]
      ),
      h(
        "a",

        {
          attrs: {
            href: "#",
            role: "button"
          },
          staticClass:
            "carousel-controls__control carousel-controls__control--next",

          class: {
            "carousel-controls__control--disabled": props.nextDisabled
          },
          on: props.nextDisabled
            ? undefined
            : {
                click: e => {
                  e.preventDefault()
                  executeListener(listeners.next)
                }
              }
        },
        [h("svg-icon", { props: { name: "chevron-right-regular" } })]
      )
    ])
  }
})
