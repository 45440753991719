var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "giphy-mission",
      class: { mobile: _vm.mobile },
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.selectedImage && _vm.missionCompleted
        ? [
            _c(
              "v-flex",
              {
                staticClass: "giphy-mission__misson-complete-message",
                attrs: { "d-flex": "", shrink: "" },
              },
              [_vm._v(" Waiting for other scribes to complete... ")]
            ),
            _c("v-flex", { staticClass: "giphy-mission__selected-image" }, [
              _c("img", { attrs: { src: _vm.selectedImage, alt: "" } }),
            ]),
          ]
        : [
            _vm.textFieldVisible
              ? _c("TextField", {
                  class: _vm.buttonStatus,
                  attrs: {
                    placeholder: _vm.placeholder,
                    status: _vm.status,
                    show: "show",
                    "button-text": "SEARCH",
                    size: "sm",
                  },
                  on: { onSubmit: _vm.getGif },
                })
              : _vm._e(),
            _vm.instructionsVisible
              ? _c(
                  "v-flex",
                  {
                    staticClass: "mission-instructions",
                    attrs: { "d-flex": "" },
                  },
                  [
                    _c("ResizableText", {
                      attrs: { message: _vm.instructions },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.error
              ? _c(
                  "v-flex",
                  {
                    staticClass: "error-message",
                    attrs: {
                      "d-flex": "",
                      "align-center": "",
                      "justify-center": "",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.error) + " ")]
                )
              : _vm._e(),
            _c(
              "v-flex",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.error,
                    expression: "!error",
                  },
                ],
                staticClass: "carousel-wrap",
                class: {
                  show: _vm.gifs.length && _vm.mode !== "explain" && !_vm.error,
                },
              },
              [
                _vm.isScribe
                  ? _c("CarouselControls", {
                      attrs: {
                        "prev-disabled": _vm.currentPage === 0,
                        "next-disabled": _vm.loading,
                      },
                      on: { prev: _vm.prevSlide, next: _vm.nextSlide },
                    })
                  : _vm._e(),
                _vm.gifs.length
                  ? _c(
                      "Carousel",
                      {
                        key: "giphy-" + _vm.searchQuery + "-" + _vm.syncedQuery,
                        ref: "carousel",
                        staticClass: "carousel pt-1",
                        attrs: {
                          perPage: _vm.nOfImagesPerSearch,
                          paginationEnabled: false,
                          navigationEnabled: false,
                          mouseDrag: false,
                        },
                        on: { pageChange: _vm.pageChange },
                      },
                      _vm._l(_vm.gifs, function (gif, i) {
                        return _c(
                          "Slide",
                          { key: "giphy-" + i },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "carousel-image-wrap",
                                staticStyle: { position: "relative" },
                                attrs: { "fill-height": "" },
                              },
                              [
                                gif.loaded === false || gif.loaded === "warning"
                                  ? _c("v-progress-circular", {
                                      staticClass: "spinner",
                                      attrs: { indeterminate: "" },
                                    })
                                  : _vm._e(),
                                gif.loaded === "warning"
                                  ? _c("div", { staticClass: "loading-warn" }, [
                                      _vm._v(
                                        " Loading takes longer than usual. Try another one "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !!gif.loaded &&
                                        gif.loaded !== "warning",
                                      expression:
                                        "!!gif.loaded && gif.loaded !== 'warning'",
                                    },
                                  ],
                                  staticClass: "carousel-image",
                                  attrs: { src: gif.url },
                                  on: {
                                    click: function ($event) {
                                      _vm.foo = false
                                    },
                                    load: function ($event) {
                                      return _vm.onLoad(i)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.selectedGif && _vm.isScribe
              ? _c(
                  "v-flex",
                  {
                    staticClass: "pt-1",
                    class: { "mobile-submit-btn": _vm.mobile },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        staticClass: "flex-shrink-0",
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "rtb-button-next",
                                    _vm._g(
                                      {
                                        staticStyle: { "margin-bottom": "6px" },
                                        attrs: { size: "sm", color: "success" },
                                        on: { click: _vm.submit },
                                      },
                                      on
                                    ),
                                    [_vm._v(" SUBMIT ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2884163900
                        ),
                      },
                      [_c("span", [_vm._v("Submit your Giphy")])]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isPresenterUser && !_vm.isHost
              ? _c(
                  "MissionStatus",
                  { staticClass: "mb-3", attrs: { show: _vm.statusText } },
                  [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }